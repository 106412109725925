const generatePrompt = (flowers) => {

    const _flowers = [...flowers];

    let flower_line = ``;
    let flower_percentages = ``;

    //bloomed Dahlias and Lisianthus flowers, with the Dahlias being all white, and the Lisianthus mixed with 90% white and 10% lavender. 

    // let prompt = `Create a divine and serene image of a wedding-appropriate bouquet. The bouquet should be entirely made up of lavish and radiant flowers. Make sure the setting is well-lit, providing a soft yet clear view of the arrangement. The depiction must have a natural appearance, expressing freshness and life in each bloom. The roses should appear realistic, with distinct, vibrant petals and charming, verdant leaves. The composition of the bouquet should be the following: `;
    
    flower_line = createFlowerLine(_flowers)

    _flowers.forEach((flower,index)=>{
        let connector = `,`
        if(index === flowers.length-1){
            connector = `.`
        }
        flower_percentages += createFlowerPercentagesLine(flower) + connector;
    })

    let prompt = `Generate an image of a bouquet, focusing exclusively on the flowers to ensure a natural and undistracted view. 
The bouquet should consist only of ${flower_line}, with ${flower_percentages} 
This composition should be presented WITHOUT any additional elements. 
NO LINES.
NO NUMBERS.
NO TEXT.
The arrangement should be depicted in a way that feels fresh and alive, capturing the essence and delicate details of each flower in a clear, well-lit setting, emphasizing the flowers according to this specifications, without any additional elements or backgrounds that detract from the bouquet’s natural appearance.
I NEED to test how the tool works with extremely simple prompts. DO NOT add any detail, just use it AS-IS
`    

    return prompt;
};


const createFlowerLine = (flowers) => {

    return flowers.reduce(
        (total, flower, index) => {
            let connector = `, `;
            if(index === flowers.length-2){
                connector = ` and `
            }
            else if(index === flowers.length-1){
                connector = ``
            }
            total = total + flower.name + connector;
            return total;
        },'');

}
const createFlowerPercentagesLine = (flower) => {

    const filtered = flower.colors.filter(item=>item.selected);

    return filtered.reduce(
        (total, color, index) => {
            let connector = `, `;
            if(index === filtered.length-2){
                connector = ` and `
            }
            else if (index === filtered.length-1){
                connector = ``
            }
            total += `${color.percentage}% of ${flower.name} are ${color.name}${connector}`
            return total;
        },'');    
}

const createImageName = (flowers) => {

    const _flowers = [...flowers];

    let name = `Image`

    let _fl = _flowers.map(flower => `${flower.name}-${flower.percentage}`)

    return `${name}-${_fl.join('-')}`

}


const calculateColorPercentages = (colors) => {


    const precentages = colors.reduce(
        (total, color, index) => {
            total = total + parseInt(color.percentage)
            return total;
        },0);

    return precentages;
}


const calculatePercentages = (flowers) => {

    return flowers.reduce(
        (total, flower, index) => {
            total = total + parseInt(flower.percentage)
            return total;
        },0);
}


const spreadColorPercentages = (flowers, flowerIndex, colorIndex,currentPercentage) => {

    const flower = flowers[flowerIndex];
    let colors = flower.colors;

    let _percentages = calculateColorPercentages(colors);

    if(currentPercentage === -1){
        //then we need to spread them as the new flower needs to be added up to 100%          

        if(_percentages < 100){

            let missingPercentage = 100 - _percentages;

            let totalColors = colors.reduce((prev,item)=>{
                if(item.selected)
                    prev+=1;
                return prev;
            },0);
            let spreadOut = parseInt(missingPercentage / totalColors);
            let remaining = missingPercentage - totalColors * spreadOut
            colors = colors.map( (item,i) => {

                if(item.selected){

                    if (i === 0)
                        item.percentage += spreadOut + remaining
                    else
                        item.percentage += spreadOut;
                }
    
                return item;
            });              
           
        }
        else{

            let totalColors = colors.reduce((prev,item)=>{
                if(item.selected)
                    prev+=1;
                return prev;
            },0);
            let spreadOut = parseInt(100 / totalColors);
            let remaining = 100 - totalColors * spreadOut
            colors = colors.map( (item,i) => {

                if(item.selected){

                    if (i === 0)
                        item.percentage = spreadOut + remaining
                    else
                        item.percentage = spreadOut;

                }
    
                return item;
            });              
        
        }
    }
    else{

        let sorted =  colors.map((index,i)=>({i,...index})).sort( (a,b)=> {
            return parseInt(b.percentage) - parseInt(a.percentage)
        });

        sorted = sorted.filter(item=>item.selected)
    
        let reversed = sorted.map(item => item).reverse()

        currentPercentage = Number(currentPercentage);
        
        if(_percentages > 100){

            console.log(_percentages)

            let _max = sorted[0];

            console.log(sorted)
            
    
            if(_max.i === colorIndex){
                _max = (sorted[1]) ? sorted[1] : false                
            }
            if(_max){
                
                console.log(_max)
                colors[_max.i].percentage = (colors[_max.i].percentage - (_percentages - 100) > 0 ) ? colors[_max.i].percentage - (_percentages - 100) : 0;
                console.log(colors[_max.i].percentage)
                colors[colorIndex].percentage = currentPercentage;
            }
        }
        else{

            let _max = reversed[0];

            if(_max.i === colorIndex){
                _max = (reversed[1]) ? reversed[1] : false                
            }
            if(_max){
                colors[_max.i].percentage = (colors[_max.i].percentage - (_percentages - 100) > 0 ) ? colors[_max.i].percentage - (_percentages - 100) : 0;
                colors[colorIndex].percentage = currentPercentage;
            }            

        }

    }

    flowers[flowerIndex].colors = colors;


    return flowers;    
}
/**
 * Spread the percentages among the flowers
 * @param {*} flowers array of flower objects
 * @param {*} flowerIndex the current index of the flower that we are changing
 * @param {*} currentPercentage if we pass -1 then spread them equally
 * @returns 
 */
const spreadPercentages = (flowers, flowerIndex, currentPercentage) => {

    const _percentages =  calculatePercentages(flowers);

    if(currentPercentage === -1){
        //then we need to spread them as the new flower needs to be added up to 100%          

        if(_percentages < 100){

            let missingPercentage = 100 - _percentages;

            let totalFlowers = flowers.length;
            let spreadOut = parseInt(missingPercentage / totalFlowers);
            let remaining = missingPercentage - totalFlowers * spreadOut
            flowers = flowers.map( (item,i) => {
                if (i === 0)
                    item.percentage += spreadOut + remaining
                else
                    item.percentage += spreadOut;
    
                return item;
            });              
           
        }
        else{

            let totalFlowers = flowers.length;
            let spreadOut = parseInt(100 / totalFlowers);
            let remaining = 100 - totalFlowers * spreadOut
            flowers = flowers.map( (item,i) => {
                if (i === 0)
                    item.percentage = spreadOut + remaining
                else
                    item.percentage = spreadOut;
    
                return item;
            });              
        
        }
    }
    else{

        let sorted =  flowers.map((index,i)=>({i,...index})).sort( (a,b)=> {
            return parseInt(b.percentage) - parseInt(a.percentage)
        });
    
        let reversed = sorted.map(item => item).reverse()

        currentPercentage = Number(currentPercentage);
        
        if(_percentages > 100){

            let _max = sorted[0];
    
            if(_max.i === flowerIndex){
                _max = (sorted[1]) ? sorted[1] : false                
            }
            if(_max){
                flowers[_max.i].percentage = (flowers[_max.i].percentage - (_percentages - 100) > 0 ) ? flowers[_max.i].percentage - (_percentages - 100) : 0;
                flowers[flowerIndex].percentage = currentPercentage;
            }
        }
        else{

            let _max = reversed[0];

            if(_max.i === flowerIndex){
                _max = (reversed[1]) ? reversed[1] : false                
            }
            if(_max){
                flowers[_max.i].percentage = (flowers[_max.i].percentage - (_percentages - 100) > 0 ) ? flowers[_max.i].percentage - (_percentages - 100) : 0;
                flowers[flowerIndex].percentage = currentPercentage;
            }            

        }

    }


    return flowers;


}

const remainingFlowerOptions = (flowerOptions,flowers) => {

    return flowerOptions.reduce( (prev, current) => {
        if(!flowers.find(item=>current.name === item.name)) prev.push(current);
        return prev;
    },[]);

}

export {
    generatePrompt, 
    calculateColorPercentages, 
    calculatePercentages, 
    spreadColorPercentages, 
    spreadPercentages,
    createImageName,
    remainingFlowerOptions
};
