// config.js

const colors = [
    {name: "Red",hash:"#b40000",percentage:0,selected:false},
    {name: "Burgundy",hash:"#8b0000",percentage:0,selected:false},
    {name: "White",hash:"#fffaf0",percentage:0,selected:false},
    {name: "Yellow",hash:"#ffff00",percentage:0,selected:false},
    {name: "Orange",hash:"#ffa500",percentage:0,selected:false},
    {name: "Blue",hash:"#0000ff",percentage:0,selected:false},
    {name: "Black",hash:"#000000",percentage:0,selected:false},    
    {name: "Green",hash:"#008000",percentage:0,selected:false},    
    {name: "Peach",hash:"#ffdab9",percentage:0,selected:false},    
    {name: "Brown",hash:"#a52a2a",percentage:0,selected:false},
    {name: "BrightYellow",hash:"#FFFF00",percentage:0,selected:false},
    {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
    {name: "Lavender",hash:"#e6e6fa",percentage:0,selected:false},
    {name: "Purple",hash:"#800080",percentage:0,selected:false},    
]

const flowerOptions = [
    {   name: "Roses",
        description: "A classic choice that symbolizes love and beauty.",
        colors: [
            {name: "Red",hash:"#b40000",percentage:100,selected:true},
            {name: "White",hash:"#fffaf0",percentage:0,selected:false},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Yellow",hash:"#ffff00",percentage:0,selected:false},
            {name: "Orange",hash:"#ffa500",percentage:0,selected:false},
            {name: "Lavender",hash:"#e6e6fa",percentage:0,selected:false},
            {name: "Blue",hash:"#0000ff",percentage:0,selected:false},
            {name: "Black",hash:"#000000",percentage:0,selected:false},
        ]
    },
    {   name: "Peonies",      
        description: "Known for their lush, full blooms and soft, romantic appeal. They symbolize happiness and prosperity.",
        colors: [
            {name: "Red",hash:"#b40000",percentage:100,selected:true},
            {name: "White",hash:"#fffaf0",percentage:0,selected:false},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Purple",hash:"#800080",percentage:0,selected:false},
        ]        
    },
    {   name: "Calla Lilies", 
        description: "Elegant and sophisticated, they symbolize magnificent beauty.",
        colors: [
            {name: "White",hash:"#fffaf0",percentage:100,selected:true},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Yellow",hash:"#ffff00",percentage:0,selected:false},
            {name: "Orange",hash:"#ffa500",percentage:0,selected:false},
            {name: "Lavender",hash:"#e6e6fa",percentage:0,selected:false},
            {name: "Dark Purple",hash:"#800080", percentage:0,selected:false},
        ]         
    },
    {   name: "Lilies of the Valley", 
        description: "Small, fragrant, bell-shaped flowers that symbolize purity and happiness.",
        colors: [
            {name: "White",hash:"#fffaf0",percentage:100,selected:true},
        ]
    },
    {   name: "Hydrangeas",   
        description: " Large blooms that add volume and texture, symbolizing heartfelt emotions.",
        colors: [
            {name: "Blue",hash:"#0000ff",percentage:100,selected:true},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "White",hash:"#fffaf0",percentage:0,selected:false},
            {name: "Lavender",hash:"#e6e6fa",percentage:0,selected:false},
            {name: "Green",hash:"#008000",percentage:0,selected:false},
        ]
    },
    {   name: "Gardenias",   
        description: "Luxurious, fragrant white flowers that symbolize purity and joy.",
        colors: [
            {name: "White",hash:"#fffaf0",percentage:100,selected:true},
        ]        
    },
    {   name: "Tulips",   
        description: "Symbolize perfect love and comfort, available in a variety of colors.", 
        colors: [
            {name: "Red",hash:"#b40000",percentage:100,selected:true},
            {name: "Yellow",hash:"#ffff00",percentage:0,selected:false},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "White",hash:"#fffaf0",percentage:0,selected:false},
            {name: "Orange",hash:"#ffa500",percentage:0,selected:false},
            {name: "Purple",hash:"#800080",percentage:0,selected:false},
            {name: "Multicolored",percentage:0,selected:false},
        ]        
    },
    {   name: "Orchids",   
        description: "Exotic and stylish, symbolizing love, beauty, and strength.",
        colors: [
            {name: "White",hash:"#fffaf0",percentage:100,selected:true},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Lavender",hash:"#e6e6fa",percentage:0,selected:false},
            {name: "Yellow",hash:"#ffff00",percentage:0,selected:false},
            {name: "Spots",percentage:0,selected:false},
            {name: "Stripes",percentage:0,selected:false},
        ]
    },
    {   name: "Dahlias",   
        description: "Known for their unique textures and vibrant colors, symbolizing commitment and an everlasting union.",
        colors: [
            {name: "White",hash:"#fffaf0",percentage:100,selected:true},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Red",hash:"#b40000",percentage:0,selected:false},
            {name: "Orange",hash:"#ffa500",percentage:0,selected:false},
            {name: "Yellow",hash:"#ffff00",percentage:0,selected:false},
            {name: "Lavender",hash:"#e6e6fa",percentage:0,selected:false},
            {name: "Purple",hash:"#800080",percentage:0,selected:false},
            {name: "Bicolors",percentage:0,selected:false},
        ]
    },
    {   name: "Ranunculus",
        description: "Layered, delicate petals symbolizing charm and attractiveness.",
        colors: [
            {name: "White",hash:"#fffaf0",percentage:100,selected:true},
            {name: "Yellow",hash:"#ffff00",percentage:0,selected:false},
            {name: "Orange",hash:"#ffa500",percentage:0,selected:false},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Red",hash:"#b40000",percentage:0,selected:false},
            {name: "Purple",hash:"#800080",percentage:0,selected:false},
        ]
    },
    {   name: "Anemones",   
        description:"With their striking dark centers, anemones offer a bold contrast and symbolize anticipation.",
        colors: [
            {name: "White",hash:"#fffaf0",percentage:100,selected:true},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Red",hash:"#b40000",percentage:0,selected:false},
            {name: "Purple",hash:"#800080",percentage:0,selected:false},
            {name: "Blue",hash:"#0000ff",percentage:0,selected:false},
        ]        
    },
    {   name: "Freesia",   
        description:"Known for their sweet fragrance and wide array of colors, symbolizing innocence.",
        colors: [
            {name: "Yellow",hash:"#ffff00",percentage:100,selected:true},
            {name: "White",hash:"#fffaf0",percentage:0,selected:false},
            {name: "Orange",hash:"#ffa500",percentage:0,selected:false},
            {name: "Red",hash:"#b40000",percentage:0,selected:false},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Purple",hash:"#800080",percentage:0,selected:false},
        ]        
    },
    {   name: "Asters",   
        description: "Represent love and patience; they come in a variety of colors and resemble daisies.", 
        colors: [
            {name: "Red",hash:"#b40000",percentage:100,selected:true},
            {name: "White",hash:"#fffaf0",percentage:0,selected:false},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
        ]        
    },
    {   name: "Garden Roses",   
        description: "More lush and fragrant than standard roses, they symbolize joy and grace.",
        colors: [
            {name: "White",hash:"#fffaf0",percentage:100,selected:true},
            {name: "Cream",percentage:0,selected:false},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Orange",hash:"#ffa500",percentage:0,selected:false},
            {name: "Yellow",hash:"#ffff00",percentage:0,selected:false},
            {name: "Red",hash:"#b40000",percentage:0,selected:false},
            {name: "Lavender",hash:"#e6e6fa",percentage:0,selected:false},
        ]
    },
    {   name: "Lisianthus",   
        description: "Elegant flowers that can resemble roses or peonies, symbolizing appreciation and gratitude.",
        colors: [
            {name: "White",hash:"#fffaf0",percentage:100,selected:true},
            {name: "Cream",percentage:0,selected:false},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Lavender",hash:"#e6e6fa",percentage:0,selected:false},
            {name: "Purple",hash:"#800080",percentage:0,selected:false},
            {name: "Bicolored",percentage:0,selected:false},
        ]                

    },
    {   name: "Snapdragons",   
        description: "Symbolize grace and strength; they add height and a unique shape to bouquets.",
        colors: [
            {name: "Yellow",hash:"#ffff00",percentage:100,selected:true},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Red",hash:"#b40000",percentage:0,selected:false},
            {name: "Orange",hash:"#ffa500",percentage:0,selected:false},
            {name: "Peach",hash:"#ffdab9",percentage:0,selected:false},                
            {name: "Purple",hash:"#800080",percentage:0,selected:false},
            {name: "Burgundy",hash:"#8b0000",percentage:0,selected:false},
        ]         
    },
    {   name: "Sunflowers",   
        description: "Known for their large blooms that symbolize adoration and loyalty.",
        colors: [
            {name: "BrightYellow",hash:"#FFFF00",percentage:100,selected:true},            
            {name: "Red",hash:"#b40000",percentage:0,selected:false},
            {name: "Brown",hash:"#a52a2a",percentage:0,selected:false},
            {name: "Orange",hash:"#ffa500",percentage:0,selected:false},
        ]         
    },
    {   name: "Sweet Peas",   
        description: "Fragrant and delicate, symbolizing blissful pleasure.",
        colors: [
            {name: "White",hash:"#fffaf0",percentage:100,selected:true},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Lavender",hash:"#e6e6fa",percentage:0,selected:false},
            {name: "Purple",hash:"#800080",percentage:0,selected:false},    
            {name: "Red",hash:"#b40000",percentage:0,selected:false},         
            {name: "BiColors",hash:"",percentage:0,selected:false},
        ], 
    },
    {   name: "Thistle",   
        description: "Adds texture and a touch of the unexpected, symbolizing strength and protection.",
        colors: [
            {name: "Blue",hash:"#0000ff",percentage:100,selected:true},            
            {name: "White",hash:"#fffaf0",percentage:0,selected:false},
            {name: "Green",hash:"#008000",percentage:0,selected:false},             
        ],         

    },
    {   name: "Wax Flowers",   
        description: "Tiny, star-shaped flowers that add texture and symbolize enduring love and patience.",
        colors: [
            {name: "White",hash:"#fffaf0",percentage:100,selected:true},
            {name: "Pink",hash:"#ffc0cb",percentage:0,selected:false},
            {name: "Lavender",hash:"#e6e6fa",percentage:0,selected:false},
            {name: "Purple",hash:"#800080",percentage:0,selected:false},
        ],                 
    },    
];

export default flowerOptions;

// prompt
// Flower bouquet made from 40% Rose flowers of which 40% have the color red 
// and 60% have the color white and 60% Peonies flowers of which 100% have the color green


// Lilies of the Valley	Small, fragrant, bell-shaped flowers that symbolize purity and happiness.	Primarily white, which is the most common and traditional color.
// Hydrangeas	Large blooms that add volume and texture, symbolizing heartfelt emotions.	Available in a range of colors including blue, pink, white, lavender, and green.
// Gardenias	Luxurious, fragrant white flowers that symbolize purity and joy.	Traditionally white, known for their pure and bright appearance.
// Tulips	Symbolize perfect love and comfort, available in a variety of colors.	Found in virtually every color, including red, yellow, pink, white, orange, purple, and even multicolored varieties.
// Orchids	Exotic and stylish, symbolizing love, beauty, and strength.	Available in white, pink, lavender, yellow, and exotic patterns including spots and stripes.
// Dahlias	Known for their unique textures and vibrant colors, symbolizing commitment and an everlasting union.	Span a wide color range, including white, pink, red, orange, yellow, lavender, and deep purple, plus bicolors.
// Ranunculus	Layered, delicate petals symbolizing charm and attractiveness.	Available in white, yellow, orange, pink, red, and purple.
