import React from 'react';
import { Box, Grid, Select, MenuItem, TextField, InputAdornment, Slider, FormControlLabel, Switch, Stack } from '@mui/material';

export const DesktopLayoutFlower = ({ flower, flowerIndex, flowerOptions, flowers, handleFlowerChange, handleFlowerPercentageChange }) =>
    <Grid container spacing={2} alignItems="center" sx={{ padding: '8px' }}>
        <Grid item xs={3} sx={{ textAlign: "left" }}>
            <Select
                sx={{ minWidth: "180px", boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}
                size="small"
                className="select"
                value={flower.name}
                name="flower"
                onChange={(e) => handleFlowerChange(e, flowerIndex)}
                required
            >
                {flowerOptions.map((option, index) => (
                    <MenuItem key={index} value={option.name} disabled={flowers.map(item => item.name).includes(option.name)}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
        <Grid item xs={7} sx={{ textAlign: "left" }}>
            <Slider
                sx={{ marginBottom: "0px",
                trackColor: 'primary',
                railColor: 'grey',
                '& .MuiSlider-thumb': {
                  color: 'purple',
                },
                '& .MuiSlider-rail': {
                  color: 'grey',
                },
                '& .MuiSlider-track': {
                  color: 'purple',
                },
                '& .MuiSlider-markLabel': {
                  color: 'black',
                } }}
                aria-label="Flower Percentage"
                value={parseInt(flower.percentage)}
                valueLabelDisplay="auto"
                shiftStep={10}
                step={2}
                onChange={(e) => handleFlowerPercentageChange(e, flowerIndex, flower)}
                marks={[{ value: 50, label: '50%' }]}
                min={0}
                max={100}
            />
        </Grid>
        <Grid item xs={2} sx={{ padding: "5px" }}>
            <TextField
                value={parseInt(flower.percentage)}
                size="small"
                onChange={(e) => handleFlowerPercentageChange(e, flowerIndex)}
                onBlur={(e) => handleFlowerPercentageChange(e, flowerIndex)}
                inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
            />
        </Grid>
    </Grid>

export const DesktopColorLayout = ({ flower, flowerIndex, handleSliderColorChange, handleCheckBoxColorChange }) => (
    <Box direction="row" spacing={2} sx={{ width: 750,  padding: '8px' }}>
        {flower?.colors && flower?.colors.map((item, colorIndex) => {
            return (
                <div key={colorIndex}>
                    <Grid container rowSpacing={4} spacing={0} alignItems="center">
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <div style={{ float: "right", backgroundColor: item.hash, width: "20px", height: "20px", boxShadow: "0px 0px 5px #ccc", borderRadius: "50%" }}>&nbsp;&nbsp;&nbsp;</div>
                        </Grid>
                        <Grid item xs={3} sx={{ textAlign: "left" }}>
                            <Stack direction="row" spacing={1} >
                                <FormControlLabel control={<Switch
                                    checked={item.selected}
                                    onChange={(e) => handleCheckBoxColorChange(e, item, colorIndex, flowerIndex)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                                    label={item.name}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                          color: item.selected ? 'black' : 'grey',
                                        }
                                      }}
                                >
                                </FormControlLabel>
                            </Stack>
                        </Grid>
                        <Grid item xs={5} alignItems="left">
                            <Slider
                                aria-label="Percentage"
                                sx={{ marginBottom: "0px" }}
                                value={item.percentage}
                                valueLabelDisplay="auto"
                                shiftStep={10}
                                step={2}
                                onChange={(e) => handleSliderColorChange(e, item, colorIndex, flowerIndex)}
                                marks={[
                                    {
                                        value: 50,
                                        label: '50%',
                                    }
                                ]}
                                min={0}
                                max={100}
                                disabled={!item.selected}
                            />
                        </Grid>
                        <Grid item xs={1}>

                        </Grid>
                        <Grid item xs={2} sx={{ padding: "5px" }}>
                            <TextField
                                value={item.percentage}
                                disabled={!item.selected}
                                onChange={(e) => handleSliderColorChange(e, item, colorIndex, flowerIndex)}
                                size="small"
                                inputProps={{
                                    step: 1,
                                    min: 0,
                                    max: 100,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            )
        })}
    </Box>
)