// App.js

import React, { useState } from 'react';
import '../styles/App.css';
import useStateCallback from '../utils/useStateCallback.js';
import FlowerChooser from './FlowerChooser.js';

import { Audio } from 'react-loader-spinner'

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import ImageIcon from '@mui/icons-material/Image';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import HomeIcon from '@mui/icons-material/Home';
import DownloadingIcon from '@mui/icons-material/Downloading';

import { saveAs } from 'file-saver'
import { createImageName } from '../utils/flowerUtils.js';



function App() {
    const [size, setSize] = useState('1024x1024'); // Default size
    const [style, setStyle] = useState('natural'); // Default style
    const [quality, setQuality] = useState('hd'); // Default quality
    const [image, setImage] = useState(null);
    const [revisedPrompt, setRevisedPrompt] = useState('');
    const [prompt, setPrompt] = useStateCallback('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [canGenerate, setCanGenerate] = useState(false);
    const [flowers, setFlowers] = useState([]);


    const handleGenerateImage = async () => {
      try {

            
          setIsGenerating(true);
          const response = await fetch(`/generate-image`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  textPrompt: prompt,
                  size,
                  style,
                  quality
              })
          });
          const data = await response.json();


          var img = new Image();

       
        //   const ctx = canvas.current.getContext('2d'); 
  
        //   img.src = data.imageUrl
          img.src =`data:image/jpeg;base64,${data.base64}`
          img.onload = function () { 

            //leave it here for future possible canvas usage
            // var hRatio = canvas.current.width  / img.width;
            // var vRatio =  canvas.current.height / img.height;
            // var ratio  = Math.min ( hRatio, vRatio );
            // var centerShift_x = ( canvas.current.width - img.width*ratio ) / 2;
            // var centerShift_y = ( canvas.current.height - img.height*ratio ) / 2;
            
            // ctx.clearRect(0,0,canvas.current.width, canvas.current.height);
            // ctx.drawImage(img, 0,0, img.width, img.height, centerShift_x,centerShift_y,img.width*ratio, img.height*ratio);

            // ctx.drawImage(img, 0,0, img.width, img.height, 0,0,img.width*ratio, img.height*ratio);
            // ctx.drawImage(img, 0, 0);
          }    

          setImage(img.src);
          setRevisedPrompt(data.revised_prompt)
          setIsGenerating(false);

      } catch (error) {
          console.error('Error generating image:', error);
      }
  };

    const handleDownloadImage = async (imageUrl) => {

        const _flowers = [...flowers];
        saveAs(imageUrl, `${createImageName(_flowers)}.jpg`) // Put your image URL here.

        //from the 
        // canvas.current.toBlob(function(blob) {
        //     saveAs(blob, "image.jpg");
        // });        
                
    };

    const handleProcessStarted = (flowers) => {

        setFlowers(flowers);

        if(flowers.length>0)
            setCanGenerate(true)
        else
            setCanGenerate(false)

    }

    const handleRefreshPage = () => {
      window.location.reload();
    };

    return (
        <>
            <Box sx={{ width: '100%', maxWidth: 840,margin:"0 auto", padding:"40px 0", textAlign:"center", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)", borderRadius: "12px", marginTop: "40px", overflow: "hidden", transition: 'boxShadow 0.3s ease-in-out',  '&:hover': {
        boxShadow: "0 15px 30px rgba(0, 0, 0, 0.25)"} 
        }}>
                <Typography sx={{ color: "#1976d2", fontWeight: 'bold',  marginBottom: 3,  fontSize: "2.5rem"}} variant="h2" gutterBottom>
                    Bouquet Creation
                </Typography>

                <FlowerChooser promptState={setPrompt} onProcessStarted={handleProcessStarted}/>
                {isGenerating && (
                    <>
                    <div className="loader">
                        <Audio
                        height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="three-dots-loading"
                        wrapperStyle
                        wrapperClass="middle"
                        />                    
                    </div>
                    <div className="prompt">
                        <Typography sx={{ color: "#2e7d32", margin: "20px 0"}} variant="h3" gutterBottom>Generating Image</Typography>
                        <Box sx={{padding: "0 20px"}}>
                            {prompt}
                        </Box>
                    </div>                
                    </>
                    )
                }
                <Box>
                    <Divider sx={{ margin: "20px 0"}} textAlign="center">Generate Image</Divider>                
                    {!canGenerate && (
                        <Typography sx={{ marginLeft:"20px", marginRight:"20px" }} variant="subtitle1" gutterBottom>
                            Start your journey by adding a flower. You can choose color wise how the bouquet will be arranged. Each flower has a set number of available colors.
                        </Typography>
                    )}
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={1} sx={{justifyContent: 'center', marginBottom: '10px', marginLeft: {xs: '10px', md: '0px'}, marginRight: {xs: '10px', md: '0px'}}}>
                        {canGenerate && (
                            <React.Fragment>
                                <Stack direction="row" spacing={1}>
                                    <FormControl fullWidth size="small" sx={{minWidth: '120px'}}>
                                        <InputLabel id="style-select-label">Style</InputLabel>
                                        <Select
                                            labelId="style-select-label"
                                            id="style-select"
                                            value={style}
                                            label="Style"
                                            onChange={(e)=>{setStyle(e.target.value)}}
                                        >
                                            <MenuItem value={'vivid'}>Vivid</MenuItem>
                                            <MenuItem value={'natural'}>Natural</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth size="small" sx={{minWidth: '120px'}}>
                                        <InputLabel id="quality-select-label">Quality</InputLabel>
                                        <Select
                                            labelId="quality-select-label"
                                            id="quality-select"
                                            value={quality}
                                            label="Quality"
                                            onChange={(e)=>{setQuality(e.target.value)}}
                                        >
                                            <MenuItem value={'standard'}>Standard</MenuItem>
                                            <MenuItem value={'hd'}>HD</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <FormControl fullWidth size="small" sx={{minWidth: '120px'}}>
                                        <InputLabel id="size-select-label">Size</InputLabel>
                                        <Select
                                            labelId="size-select-label"
                                            id="size-select"
                                            value={size}
                                            label="Size"
                                            onChange={(e)=>{setSize(e.target.value)}}
                                        >
                                            <MenuItem value={'1024x1024'}>1024x1024</MenuItem>
                                            <MenuItem value={'1792x1024'}>1792x1024</MenuItem>
                                            <MenuItem value={'1024x1792'}>1024x1792</MenuItem>
                                        </Select>
                                    </FormControl>                              
                                    <FormControl fullWidth sx={{minWidth: '150px'}}>
                                        <Button variant="outlined" startIcon={(image)?<RestartAltIcon />:<ImageIcon />} onClick={async () => handleGenerateImage()}>
                                            {(image)?"Try Again":"Generate"}
                                        </Button>                                
                                    </FormControl>                        
                                </Stack>
                            </React.Fragment>
                        )}
                    </Stack>
                    <Stack direction="row" spacing={1} sx={{justifyContent:"center",marginBottom:"10px"}}>
                        {image && (
                            <Stack direction="row" spacing={1}>
                                <Button variant="contained" 
                                startIcon={ <DownloadingIcon /> }
                                onClick={async () => handleDownloadImage(image)} 
                                color="success">Download</Button>
                                <Button variant="outlined" 
                                startIcon={ <HomeIcon /> }
                                onClick={async () => handleRefreshPage()} 
                                color="success">New Bouquet</Button>
                            </Stack>
                        )}
                    </Stack>             
                </Box>
                {canGenerate && (                    
                    <Box>
                        <Stack direction={{xs: 'column', md: 'row'}} spacing={1} sx={{justifyContent: 'center', marginTop: '20px', marginBottom: '10px', marginLeft: {xs: '10px', md: '0px'}, marginRight: {xs: '10px', md: '0px'}}}>
                            <textarea 
                            value={prompt}
                            rows={12} cols={80}  
                            style={{  resize: 'none',
                            border: '2px solid #1565c0',
                            borderRadius: '8px',
                            padding: '10px',
                            backgroundColor: '#F0F0F0',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            outline: 'none',
                            }}                   
                            onChange={(e)=>{setPrompt(e.target.value)}}
                            ></textarea>
                        </Stack>                    
                    </Box>
                )}                     
                {image && (
                    <>
                        <div className="image-container">
                            <img src={image} alt="Generated" className="generated-image" />
                        </div>
                            <Typography sx={{ color: "#1976d2", marginLeft:"20px", marginRight:"20px" }} variant="h3" gutterBottom>
                                Revised Prompt
                            </Typography>                        
                            <Typography sx={{ color: "#9E9E9E", marginLeft:"20px", marginRight:"20px" }} variant="subtitle1" gutterBottom>
                                {revisedPrompt}
                            </Typography>
                    </>


                )}
      
            </Box>
        </>
         

    );
}

export default App;
