import React from 'react';
import { Box, Grid, Select, MenuItem, TextField, InputAdornment, Slider, FormControlLabel, Switch, Divider, Stack } from '@mui/material';

export const MobileLayoutFlower = ({ flower, flowerIndex, flowerOptions, flowers, handleFlowerChange, handleFlowerPercentageChange }) => (
    <Grid container spacing={2} alignItems="center">
        <Grid item xs={8} md={3} sx={{ textAlign: "left" }}>
            <Select
                sx={{  width: "100%",
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                     }}
                size="small"
                className="select"
                value={flower.name}
                name="flower"
                onChange={(e) => handleFlowerChange(e, flowerIndex)}
                required
            >
                {flowerOptions.map((option, index) => (
                    <MenuItem key={index} value={option.name} disabled={flowers.map(item => item.name).includes(option.name)}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
        <Grid item xs={4} md={2}>
            <TextField
                sx={{ width: "calc(100% - 20px)", marginRight: "20px" }}
                value={parseInt(flower.percentage)}
                size="small"
                onChange={(e) => handleFlowerPercentageChange(e, flowerIndex)}
                onBlur={(e) => handleFlowerPercentageChange(e, flowerIndex)}
                inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
            />
        </Grid>
        <Grid item xs={12} md={7} sx={{ textAlign: "left" }}>
            <Slider
                sx={{ width: "calc(100% - 40px)", marginLeft: "20px", marginRight: "20px" }}
                aria-label="Flower Percentage"
                value={parseInt(flower.percentage)}
                valueLabelDisplay="auto"
                shiftStep={10}
                step={2}
                onChange={(e) => handleFlowerPercentageChange(e, flowerIndex, flower)}
                marks={[{ value: 50, label: '50%' }]}
                min={0}
                max={100}
            />
        </Grid>
    </Grid>
);

export const MobileColorLayout = ({ flower, flowerIndex, handleSliderColorChange, handleCheckBoxColorChange }) => (
    <Box direction="row" spacing={2} sx={{ width: "100%" }}>
        {flower?.colors && flower?.colors.map((item, colorIndex) => (
            <div key={colorIndex}>
                <Grid container rowSpacing={1} spacing={0} alignItems="center">
                    <Grid item xs={1} md={1} sx={{ textAlign: "right" }}>
                        <div style={{ float: "right", backgroundColor: item.hash, width: "20px", boxShadow: "0px 0px 5px #ccc", borderRadius: "20px" }}>&nbsp;&nbsp;&nbsp;</div>
                    </Grid>
                    <Grid item xs={5} md={3} sx={{ textAlign: "left" }}>
                        <Stack direction="row" spacing={1}>
                            <FormControlLabel
                                control={<Switch
                                    checked={item.selected}
                                    onChange={(e) => handleCheckBoxColorChange(e, item, colorIndex, flowerIndex)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                                label={item.name}
                            >
                            </FormControlLabel>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} md={2} sx={{ padding: "5px" }}>
                        <TextField
                            sx={{ width: "calc(100% - 20px)", marginRight: "20px" }}
                            value={item.percentage}
                            disabled={!item.selected}
                            onChange={(e) => handleSliderColorChange(e, item, colorIndex, flowerIndex)}
                            size="small"
                            inputProps={{
                                step: 1,
                                min: 0,
                                max: 100,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} alignItems="left">
                        <Slider
                            aria-label="Percentage"
                            sx={{ width: "calc(100% - 40px)", marginRight: "20px", marginLeft: "20px", marginBottom: "20px" }}
                            value={item.percentage}
                            valueLabelDisplay="auto"
                            shiftStep={10}
                            step={2}
                            onChange={(e) => handleSliderColorChange(e, item, colorIndex, flowerIndex)}
                            marks={[
                                {
                                    value: 50,
                                    label: '50%',
                                }
                            ]}
                            min={0}
                            max={100}
                            disabled={!item.selected}
                        />
                        <Divider sx={{ marginTop: "20px", marginBottom: "20px" }}></Divider>
                    </Grid>
                </Grid>
            </div>
        ))}
    </Box>
);