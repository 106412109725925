// FlowerChooser.js

import { useState } from "react";
import flowerOptions from "../config/config.js";

import {generatePrompt, calculateColorPercentages, spreadPercentages, spreadColorPercentages, remainingFlowerOptions } from '../utils/flowerUtils';

import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { Typography } from "@mui/material";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { MobileLayoutFlower, MobileColorLayout } from './MobileLayout';
import { DesktopLayoutFlower, DesktopColorLayout } from './DesktopLayout';

function FlowerChooser({ promptState, onProcessStarted }) {
    // Use the useTheme and useMediaQuery hooks to determine if the screen is mobile/tablet
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [flowers, setFlowers] = useState([]);
    const [cachedFlowers, setCachedFlowers] = useState({});

    const handleFlowerPercentageChange = (event,flowerIndex, prev) => {

        let _flowers = [...flowers]
        const currentPercentage = parseInt(event.target.value);
        _flowers[flowerIndex].percentage = currentPercentage;
        _flowers = spreadPercentages(_flowers,flowerIndex,currentPercentage);
        setFlowers(_flowers);
        promptState(generatePrompt(_flowers));
    }

    // flower change
    const handleFlowerChange = (e, i) => {

        const _flowers = [...flowers];
        const value = e.target.value;

        let currentFlowerOptions = remainingFlowerOptions(flowerOptions,_flowers)
        let found = currentFlowerOptions.find(item=>item.name===value)

        //first check in the cached flowers maybe we selected a flower setup some percentages then changed it to another flower and you know decided to go back to the old flower
        let cachedFound = cachedFlowers[value];
        //set the new flower to either the cached value or the one from the available flower options
        let flower = (cachedFound) ? cachedFound : found

        if(flower){

            //cache the previous flower in case we have some changes and what not (like color choices that we would like to use later on)
            let newCachedFlowers = {...cachedFlowers};
            newCachedFlowers[_flowers[i].name] = _flowers[i];
            setCachedFlowers(newCachedFlowers);

            let currentFlowerPercentage = _flowers[i].percentage
            _flowers[i] = flower;
            _flowers[i].percentage = currentFlowerPercentage   
            
            setFlowers(_flowers)
        }
        
    };

    // add a flower from the list of the available flowers also deal with the percentages
    const handleAddFlower = () => {

        let _flowers = [...flowers];

        const remainingFlowers = remainingFlowerOptions(flowerOptions,_flowers);
        const nextFlower = (remainingFlowers[0]) ? remainingFlowers[0] : false;
        if(nextFlower){
            //here calculate the percentage that we will use for this flower, will probably split  the others and always use to 100
            _flowers.push(nextFlower)
            _flowers = spreadPercentages(_flowers,_flowers.length-1,-1);
        }        

        setFlowers(_flowers)
        promptState(generatePrompt(_flowers));
        onProcessStarted(_flowers)

    };

    // delete flower
    const handleDeleteFlower = (i) => {
        let _flowers = [...flowers];

         _flowers.splice(i, 1);
         //do we do a spread? TODO: decide how to handle this case
         _flowers = spreadPercentages(_flowers,_flowers.length-1,-1);         
        setFlowers(_flowers);
        promptState(generatePrompt(_flowers));
        onProcessStarted(_flowers) 

    };

    const handleSliderColorChange = (event,color,colorIndex,flowerIndex) => {

        let _flowers = [...flowers];

        const currentPercentage = parseInt(event.target.value);

        _flowers[flowerIndex].colors[colorIndex].percentage = currentPercentage;
        _flowers = spreadColorPercentages(_flowers,flowerIndex,colorIndex,currentPercentage);

        setFlowers(_flowers);
        promptState(generatePrompt(_flowers));        

    }

    const handleCheckBoxColorChange = (event, color,colorIndex,flowerIndex) => {

        const _flowers = [...flowers];

        _flowers[flowerIndex].colors[colorIndex].selected = event.target.checked
        _flowers[flowerIndex].colorPercentages = calculateColorPercentages(_flowers[flowerIndex].colors);

        setFlowers(_flowers);
        promptState(generatePrompt(_flowers));

    }

    return (
        <div>
            <Stack spacing={2} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                {flowers.length>0 && 
                
                <Stack direction="column" spacing={2} sx={{ width: '100%', maxWidth: 750, padding: 2, bgcolor: 'background.paper', boxShadow: '0px 3px 10px rgba(0,0,0,0.1)' }}>
                    {flowers.map((flower, flowerIndex) => (
                        <div key={flowerIndex}>
                            <Divider sx={{ marginTop:"20px", marginBottom:"20px"}}>
                                <Chip label={flower.name} size="small" color="primary" />                                    
                            </Divider>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: 'text.secondary' }}>
                                {flower.description}
                            </Typography>
                            <IconButton aria-label="delete" onClick={() => handleDeleteFlower(flowerIndex)} sx={{ color: 'error.main' }}>
                                <DeleteIcon />
                            </IconButton>                              
                            {isMobile ? (
                                <MobileLayoutFlower
                                    flower={flower}
                                    flowerIndex={flowerIndex}
                                    flowerOptions={flowerOptions}
                                    flowers={flowers}
                                    handleFlowerChange={handleFlowerChange}
                                    handleFlowerPercentageChange={handleFlowerPercentageChange}
                                />
                            ) : (
                                <DesktopLayoutFlower
                                    flower={flower}
                                    flowerIndex={flowerIndex}
                                    flowerOptions={flowerOptions}
                                    flowers={flowers}
                                    handleFlowerChange={handleFlowerChange}
                                    handleFlowerPercentageChange={handleFlowerPercentageChange}
                                />
                            )}
                            {flowers.length>0 && (
                                <>
                                {isMobile ? (
                                    <>
                                        <Divider sx={{ marginTop:"20px", marginBottom:"20px"}}>
                                            <Chip label="Colors" size="small" color="primary" />                                    
                                        </Divider>
                                        <MobileColorLayout
                                            flower={flower}
                                            flowerIndex={flowerIndex}
                                            handleSliderColorChange={handleSliderColorChange}
                                            handleCheckBoxColorChange={handleCheckBoxColorChange}
                                        />                                    
                                    </>
                                ) : (
                                    <DesktopColorLayout
                                        flower={flower}
                                        flowerIndex={flowerIndex}
                                        handleSliderColorChange={handleSliderColorChange}
                                        handleCheckBoxColorChange={handleCheckBoxColorChange}
                                    />
                                )}
                                </>
                            )}
                        </div>
                    ))}
                </Stack>
                }
                <Grid container rowSpacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Button onClick={handleAddFlower} variant="contained" startIcon={<AddIcon />} >
                                Add Flower
                        </Button>                   
                    </Grid>
                </Grid>
            </Stack>
        </div>
    );
}

export default FlowerChooser;
